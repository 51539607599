<template>
  <div>
    <v-row dense class="mb-5">
      <v-col cols="4">
        <v-row dense>
          <v-col cols="3">
            <select-pos-model
              v-model="idPos"
              :label="$t('labels.pos')"
              :disabled="isOrderCreated"
              @change="onPosChange"
              :single-line="false"
            ></select-pos-model>
          </v-col>
          <v-col cols="4">
            <select-employee-model
              v-model="id_employee_care"
              :label="$t('labels.employee_care')"
              :single-line="false"
              @change="onPosChange"
            ></select-employee-model>
          </v-col>
          <v-col cols="5">
            <div class="w-100 d-flex align-center justify-center">
              <v-radio-group
                dense
                row
                hide-details
                v-model="buyer.gender"
                class="mt-1 c-input-small"
                @change="onPosChange"
              >
                <v-radio :value="1" label="Khách nam" class="mr-2"></v-radio>
                <v-radio :value="2" label="Khách nữ"></v-radio>
              </v-radio-group>
            </div>
            <!-- <v-autocomplete v-model="buyer.gender" :items="genderOptions" :label="$t('labels.gender')" dense
                            outlined hide-details class="c-input-small"></v-autocomplete> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row dense>
          <v-col cols="4">
            <input-qr-scan-model
              ref="inputQr"
              :disabled="!idPos || isOrderCreated"
              v-model="code"
              :label="label"
              @keyupEnter="inputScan"
            ></input-qr-scan-model>
          </v-col>
          <v-col cols="4">
            <v-text-field
              ref="inputQrBuyer"
              :disabled="
                !idPos || isOrderCreated || isScanHidden || buyerStep > 5
              "
              v-model="buyerCode"
              :label="buyerLabel"
              @keyup.enter="buyerScan"
              outlined
              dense
              hide-details=""
              class="c-input-small c-input-buyer"
            >
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-btn
                    color="purple"
                    class="white--text"
                    small
                    @click="onHiddenInfoClick"
                    :disabled="
                      !idPos || isOrderCreated || isScanHidden || buyerStep > 5
                    "
                  >
                    {{ $t("labels.hidden_info") }}
                  </v-btn>
                </v-fade-transition>
              </template>
            </v-text-field>
            <!-- <input-qr-scan-model ref="inputQrBuyer"
                            :disabled="!idPos || isOrderCreated || isScanHidden || buyerStep > 5" v-model="buyerCode"
                            :label="buyerLabel" @keyupEnter="buyerScan"></input-qr-scan-model> -->
          </v-col>
          <!-- <v-col cols="3">
                        <v-btn color="purple" class="white--text" style="height: 40px" block @click="onHiddenInfoClick">
                            {{ $t('labels.hidden_info') }}
                        </v-btn>
                    </v-col> -->
          <v-col cols="2">
            <v-btn
              color="error"
              style="height: 40px"
              block
              @click="showRemoveItemDialog"
            >
              {{ $t("labels.remove_item") }}
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="id_source"
              :items="sourceOptions"
              :label="$t('labels.customer_source')"
              dense
              outlined
              hide-details
              class="c-input-small"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="2">
        <v-btn
          color="success"
          block
          :disabled="isDisableBtnSubmit"
          @click="createOrder"
          style="height: 40px"
        >
          THANH TOÁN THÀNH CÔNG
        </v-btn>
      </v-col>
    </v-row>

    <div
      class="mb-5"
      v-if="!!buyer.phone || items.length > 0 || exchangeItems.length > 0"
    >
      <v-row dense>
        <v-col cols="12" md="8">
          <v-card class="card-custom-shadow pa-3" style="min-height: 120px">
            <v-simple-table class="table-padding-2-no-top">
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" class="text-center">Điện thoại</th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 80px"
                  >
                    Giới tính
                  </th>
                  <th role="columnheader" class="text-center">Tên</th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 80px"
                  >
                    Ngày sinh
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 80px"
                  >
                    Tháng sinh
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 80px"
                  >
                    Năm sinh
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 100px"
                  >
                    Điểm tích lũy
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 100px"
                  >
                    + Tích điểm
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td>{{ buyer.phone }}</td>
                  <td>
                    {{
                      buyer.gender === 1
                        ? "Nam"
                        : buyer.gender === 2
                        ? "Nữ"
                        : ""
                    }}
                  </td>
                  <td>{{ buyer.name }}</td>
                  <td>{{ buyer.birthday_day }}</td>
                  <td>{{ buyer.birthday_month }}</td>
                  <td>{{ buyer.birthday_year }}</td>
                  <td>
                    <span class="font-weight-medium primary--text">
                      {{ formatNumber(buyer.available_point) }}
                    </span>
                  </td>
                  <td>
                    <span class="font-weight-medium success--text">
                      {{ formatNumber(price.accrue_point) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="12" md="2">
          <v-card class="card-custom-shadow pa-3" style="min-height: 120px">
            <v-simple-table class="table-padding-2-no-top">
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" class="text-center">Sản Phẩm</th>
                  <th role="columnheader" class="text-center">SKU</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td class="fs-16px font-weight-medium">{{ sumUid }}</td>
                  <td class="fs-16px font-weight-medium">{{ sumSku }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="12" md="2">
          <v-card class="card-custom-shadow pa-3" style="min-height: 120px">
            <v-simple-table class="table-padding-2-no-top">
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" class="text-center">Mã bao bì</th>
                  <th role="columnheader" class="text-center">Số lượng</th>
                  <th role="columnheader" class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, key) in packages"
                  :key="`bb_${item.id}_${key}`"
                  class="text-center"
                >
                  <td class="fs-16px font-weight-medium">{{ item.barcode }}</td>
                  <td class="fs-16px font-weight-medium">
                    {{ item.quantity }}
                  </td>
                  <td>
                    <v-btn
                      x-small
                      color="error"
                      @click="removePackaging(item.id)"
                      :disabled="isDisabledPaymentInput"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="mb-5" v-if="items.length > 0 || exchangeItems.length > 0">
      <v-card class="card-custom-shadow pa-3">
        <v-simple-table class="table-padding-2-no-top">
          <thead class="v-data-table-header">
            <tr>
              <!-- <th role="columnheader" class="text-center">Nhân viên</th> -->
              <th role="columnheader" class="text-center">Giá trị hóa đơn</th>
              <th role="columnheader" class="text-center">KM. Hóa đơn</th>
              <th role="columnheader" class="text-center" style="width: 130px">
                Mã giảm giá
              </th>
              <th role="columnheader" class="text-center">Thanh toán</th>
              <th
                role="columnheader"
                class="text-center indigo--text"
                style="width: 130px"
              >
                Chuyển Khoản
              </th>
              <th
                role="columnheader"
                class="text-center indigo--text"
                style="width: 130px"
              >
                Tiền mặt
              </th>
              <th
                role="columnheader"
                class="text-center indigo--text"
                style="width: 130px"
              >
                Trả thẻ
              </th>
              <th
                role="columnheader"
                class="text-center indigo--text"
                style="width: 130px"
              >
                Dùng điểm
              </th>
              <th
                role="columnheader"
                class="text-center indigo--text"
                style="width: 130px"
              >
                Trả lại
              </th>
              <th role="columnheader" class="text-center" style="width: 130px">
                Kiểm tra
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
              <!-- <td class="font-weight-medium" style="font-size: 16px">{{ me.display_name }}</td> -->
              <td>
                <span class="fs-16px font-weight-medium primary--text">
                  {{ formatNumber(price.bill_price) }}
                </span>
              </td>
              <td>
                <span class="fs-16px font-weight-medium success--text">
                  {{ formatNumber(price.bill_promotion) }}
                </span>
              </td>
              <td>
                <v-autocomplete
                  v-model="payment.id_voucher"
                  :items="vouchers"
                  :disabled="!buyer.id || isDisabledPaymentInput"
                  @change="getPrice"
                  class="c-input-small"
                  :placeholder="voucherPlaceholder"
                  outlined
                  dense
                  hide-details
                  clearable
                ></v-autocomplete>
              </td>
              <td>
                <span class="fs-16px font-weight-medium error--text">
                  {{ formatNumber(price.payment_price) }}
                </span>
              </td>
              <td>
                <vuetify-money
                  v-model.number="payment.transfer"
                  type="number"
                  :disabled="isDisabledPaymentInput"
                  :options="globalMoneyOptions"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                />
              </td>
              <td>
                <vuetify-money
                  v-model.number="payment.cash"
                  type="number"
                  :disabled="isDisabledPaymentInput"
                  :options="globalMoneyOptions"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                />
              </td>
              <td>
                <vuetify-money
                  v-model.number="payment.card"
                  type="number"
                  :disabled="isDisabledPaymentInput"
                  :options="globalMoneyOptions"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                />
              </td>
              <td>
                <vuetify-money
                  v-model.number="payment.point"
                  :disabled="!buyer.id || isDisabledPaymentInput"
                  type="number"
                  :options="globalMoneyOptions"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                />
              </td>
              <td>
                <vuetify-money
                  v-model.number="payment.return"
                  :disabled="isDisabledPaymentInput"
                  type="number"
                  :options="globalMoneyOptions"
                  class="c-input-small"
                  outlined
                  dense
                  hide-details
                />
              </td>
              <td>
                <div
                  class="fs-18 font-weight-medium"
                  style="height: 40px; width: 100%; line-height: 40px"
                  :class="
                    paymentCheck === 0
                      ? 'white--text green'
                      : 'error--text yellow'
                  "
                >
                  {{ formatNumber(paymentCheck) }}
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>

    <div
      class="mb-5"
      v-if="
        promotionUids.length > 0 ||
        promotionGoodsItems.length > 0 ||
        goodsItems.length > 0
      "
    >
      <v-card class="card-custom-shadow pa-3">
        <v-simple-table class="table-padding-2-no-top">
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center purple--text">
                Barcode
              </th>
              <th role="columnheader" class="text-center purple--text">Ảnh</th>
              <th role="columnheader" class="text-center purple--text">
                Sản phẩm
              </th>
              <th role="columnheader" class="text-center purple--text">
                Đặc tả
              </th>
              <th role="columnheader" class="text-center purple--text">Size</th>
              <th role="columnheader" class="text-center purple--text">
                Số lượng
              </th>
              <th role="columnheader" class="text-center purple--text">
                Khả dụng
              </th>
              <th role="columnheader" class="text-center purple--text">
                Nguyên giá
              </th>
              <th role="columnheader" class="text-center purple--text">
                Giá khuyến mại
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(item, index) in promotionUids"
              :key="`pi_${item.id_goods}_${index}`"
            >
              <td>
                {{ item.customer_goods_barcode }} <br />
                <span class="error--text">{{ item.sku_uid }}</span>
              </td>
              <td>
                <ImageViewer
                  width="60px"
                  height="auto"
                  v-if="item.url_images"
                  :url="item.url_images"
                />
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.available_quantity }}</td>
              <td>
                <span class="font-weight-medium">
                  {{ formatNumber(item.total_base_price) }}
                </span>
              </td>
              <td>
                <span class="font-weight-medium success--text">
                  {{ formatNumber(item.total_bill_price) }}
                </span>
              </td>
            </tr>
            <tr
              class="text-center"
              v-for="(item, index) in promotionGoodsItems"
              :key="`pi_${item.id_goods}_${index}`"
            >
              <td>{{ item.customer_goods_barcode }}</td>
              <td>
                <ImageViewer
                  width="60px"
                  height="auto"
                  v-if="item.url_images"
                  :url="item.url_images"
                />
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.available_quantity }}</td>
              <td>
                <span class="font-weight-medium">
                  {{ formatNumber(item.total_base_price) }}
                </span>
              </td>
              <td>
                <span class="font-weight-medium success--text">
                  {{ formatNumber(item.total_bill_price) }}
                </span>
              </td>
            </tr>
            <tr
              class="text-center"
              v-for="(item, index) in goodsItems"
              :key="`i_${item.id_goods}_${index}`"
            >
              <td>{{ item.customer_goods_barcode }}</td>
              <td>
                <ImageViewer
                  width="60px"
                  height="auto"
                  v-if="item.url_images"
                  :url="item.url_images"
                />
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.available_quantity }}</td>
              <td>
                <span class="font-weight-medium">
                  {{ formatNumber(item.total_base_price) }}
                </span>
              </td>
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>

    <div class="mb-5" v-if="promotionComboItems.length > 0">
      <v-card class="card-custom-shadow pa-3">
        <v-simple-table class="table-padding-2-no-top">
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center primary--text">
                Combo
              </th>
              <th role="columnheader" class="text-center primary--text">
                Số lượng
              </th>
              <!--<th role="columnheader" class="text-center">Khả dụng</th>-->
              <th role="columnheader" class="text-center primary--text">
                Nguyên giá
              </th>
              <th role="columnheader" class="text-center primary--text">
                Giá khuyến mại
              </th>
              <th role="columnheader" class="text-center primary--text">
                Barcode
              </th>
              <th role="columnheader" class="text-center primary--text">
                Sản phẩm
              </th>
              <th role="columnheader" class="text-center primary--text">
                Đặc tả
              </th>
              <th role="columnheader" class="text-center primary--text">
                Size
              </th>
              <th role="columnheader" class="text-center primary--text">Ảnh</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(item, index) in promotionComboItems"
              :key="`ci_${index}`"
            >
              <td>{{ item.code }}</td>
              <td>{{ item.quantity }}</td>
              <!--<td></td>-->
              <td>
                <span class="font-weight-medium">
                  {{ formatNumber(item.total_base_price) }}
                </span>
              </td>
              <td>
                <span class="font-weight-medium success--text">
                  {{ formatNumber(item.total_bill_price) }}
                </span>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.items"
                  :key="`cc_${index}_${cIndex}`"
                >
                  {{ cItem.customer_goods_barcode }} *
                  {{ cItem.combo_item_quantity }}
                </div>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.items"
                  :key="`cc_${index}_${cIndex}`"
                >
                  {{ cItem.name }}
                </div>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.items"
                  :key="`cc_${index}_${cIndex}`"
                >
                  {{ cItem.description }}
                </div>
              </td>
              <td>
                <div
                  v-for="(cItem, cIndex) in item.items"
                  :key="`cc_${index}_${cIndex}`"
                >
                  {{ cItem.size }}
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>

    <div class="mb-5" v-if="exGoodsItems.length > 0">
      <v-card class="card-custom-shadow pa-3">
        <v-simple-table class="table-padding-2-no-top">
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center error--text">
                Mã đơn đổi trả
              </th>
              <th role="columnheader" class="text-center error--text">Ảnh</th>
              <th role="columnheader" class="text-center error--text">UID</th>
              <th role="columnheader" class="text-center error--text">
                Barcode
              </th>
              <th role="columnheader" class="text-center error--text">
                Sản phẩm
              </th>
              <th role="columnheader" class="text-center error--text">
                Đặc tả
              </th>
              <th role="columnheader" class="text-center error--text">Size</th>
              <th role="columnheader" class="text-center error--text">
                Giá trị
              </th>
              <th role="columnheader" class="text-center error--text">
                Ưu đãi đã hưởng
              </th>
              <th role="columnheader" class="text-center error--text">
                Thanh toán
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(item, index) in exGoodsItems"
              :key="`i_${item.id_goods}_${index}`"
            >
              <td>{{ item.tracking_id }}</td>
              <td>
                <ImageViewer
                  width="60px"
                  height="auto"
                  v-if="item.url_images"
                  :url="item.url_images"
                />
              </td>
              <td>{{ item.sku_uid }}</td>
              <td>{{ item.customer_goods_barcode }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
              <td>{{ formatNumber(-item.bill_price) }}</td>
              <td>{{ formatNumber(-item.extra_promotion) }}</td>
              <td>{{ formatNumber(-item.payment_price) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>

    <v-dialog v-model="dialogRemoveItem" persistent max-width="350px">
      <OrderCreateRemoveUid
        v-if="dialogRemoveItem"
        title="Hủy sản phẩm"
        description="Quét UID để hủy sản phẩm"
        label="UID"
        placeholder="UID"
        :codes="skuUidCodes"
        @cancel="hideRemoveItemDialog"
        @confirm="confirmRemoveItem"
      />
    </v-dialog>

    <v-dialog v-model="dialogConfirmReturn" persistent max-width="350px">
      <ConfirmReturnItem
        v-if="dialogConfirmReturn"
        :item="returnItem"
        @cancel="hideConfirmReturnDialog"
        @confirm="confirmReturnItem"
      />
    </v-dialog>

    <OrderStamp
      v-if="createdOrder && createdOrder.id && !isPrinted"
      @onPrinted="onPrinted"
      :order="createdOrder"
    />
  </div>
</template>

<script>
import {
  BUYER_HIDDEN_INFO_CODE,
  GENDER_FEMALE_CODE,
  GENDER_MALE_CODE,
  GENDER_OPTIONS,
  POS_ORDER_BUYER_STEP_KEYS,
  REMOVE_ITEM_CODE,
  ORDER_SOURCE_OPTIONS,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "OrderCreateItem",
  components: {
    OrderCreateRemoveUid: () => import("@/components/pos/OrderCreateRemoveUid"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
    ConfirmReturnItem: () => import("@/components/pos/ConfirmReturnItem"),
    OrderStamp: () => import("@/components/pos/OrderStamp"),
  },
  props: {
    itemIndex: {
      type: [String, Number],
      default: () => null,
    },
  },
  data: () => ({
    idPos: null,
    code: null,
    buyerCode: null,
    buyerStep: 1,
    me: {},
    isLoading: false,
    id_employee_care: null,
    id_source: null,
    buyer: {
      phone: null,
      name: null,
      gender: null,
      birthday_day: null,
      birthday_month: null,
      birthday_year: null,
      available_point: null,
    },
    payment: {
      bill: null,
      promotion_bill: null,
      value: null,
      id_voucher: null,
      transfer: "",
      cash: "",
      card: "",
      point: "",
      return: "",
    },
    price: {
      promotionProductItems: [],
      promotionProductViews: [],
      promotionComboItems: [],
      promotionComboViews: [],
      promotionBillItems: [],
      promotionVoucherItems: [],
      promotionUidItems: [],
      promotionUidViews: [],
      baseItems: [],
      baseViews: [],
      exItems: [],
      base_price: 0,
      sale_price: 0,
      product_promotion: 0,
      bill_price: 0,
      bill_promotion: 0,
      voucher_promotion: 0,
      payment_price: 0,
    },
    items: [],
    exchangeItems: [],
    vouchers: [],
    packages: [],
    dialogRemoveItem: false,
    dialogConfirmReturn: false,
    returnItem: {},
    createdOrder: {},
    isPrinted: false,
    genderOptions: [...GENDER_OPTIONS],
    sourceOptions: [...ORDER_SOURCE_OPTIONS],
    isScanHidden: false,
  }),
  computed: {
    isOrderCreated() {
      return !!this.createdOrder.id;
    },
    label() {
      return this.$t(`labels.pos_order_step_1`);
    },
    buyerLabel() {
      return this.$t(`labels.pos_buyer_step_${this.buyerStep}`);
    },
    voucherPlaceholder() {
      if (this.vouchers.length === 0) {
        return "";
      }
      return `${this.vouchers.length} Mã`;
    },
    goodsItems() {
      return this.price.baseViews.map((item) => {
        const goods = this.items.find((g) => item.id_goods === g.id_goods);
        return { ...goods, ...item };
      });
    },
    exGoodsItems() {
      return this.price.exItems.map((item) => {
        const goods = this.exchangeItems.find(
          (g) => item.id_goods === g.id_goods
        );
        return { ...goods, ...item };
      });
    },
    promotionUids() {
      return this.price.promotionUidViews.map((item) => {
        const goods = this.items.find((g) => item.id_goods === g.id_goods);
        return { ...goods, ...item };
      });
    },
    promotionGoodsItems() {
      return this.price.promotionProductViews.map((item) => {
        const goods = this.items.find((g) => item.id_goods === g.id_goods);
        return { ...goods, ...item };
      });
    },
    promotionComboItems() {
      return this.price.promotionComboViews.map((item) => {
        const comboItems = [];
        item.items.forEach((cItem) => {
          const checkItem = comboItems.find(
            (c) => c.id_goods === cItem.id_goods
          );
          if (!checkItem) {
            const goods = this.items.find((g) => cItem.id_goods === g.id_goods);
            comboItems.push({ ...goods, ...cItem });
          }
        });
        item.items = comboItems;
        return item;
      });
    },
    skuUidCodes() {
      const codes = [];
      if (this.items.length === 0 && this.exchangeItems.length === 0) {
        return codes;
      }
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        codes.push(...item.sku_uids);
      }
      for (let i = 0; i < this.exchangeItems.length; i++) {
        const item = this.exchangeItems[i];
        codes.push(...item.sku_uids);
      }
      return codes;
    },
    sumUid() {
      let sum = 0;
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        sum += item.sku_uids.length;
      }
      return sum;
    },
    sumSku() {
      return this.items.length;
    },
    removeCodes() {
      return [REMOVE_ITEM_CODE];
    },
    paymentValue() {
      return +this.price.payment_price;
    },
    paymentTransfer() {
      return +this.payment.transfer;
    },
    paymentCash() {
      return +this.payment.cash;
    },
    paymentCard() {
      return +this.payment.card;
    },
    paymentPoint() {
      return +this.payment.point;
    },
    paymentReturn() {
      return +this.payment.return;
    },
    paymentCheck() {
      return (
        this.paymentValue +
        this.paymentReturn -
        this.paymentTransfer -
        this.paymentCash -
        this.paymentCard -
        this.paymentPoint
      );
    },
    isDisabledPaymentInput() {
      return this.isOrderCreated; //|| this.paymentValue <= 0
    },
    isDisableBtnSubmit() {
      if (!this.idPos) {
        return true;
      }
      if (this.items.length === 0 && this.exchangeItems.length === 0) {
        return true;
      }
      if (!this.packages || this.packages.length === 0) {
        return true;
      }
      if (this.isOrderCreated) {
        return true;
      }
      /* if (this.paymentCheck < 0) {
                return true
            } */
      if (this.paymentCheck !== 0) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const { me } = window;
    this.me = { ...me };
  },
  methods: {
    onPosChange() {
      setTimeout(() => {
        this.$refs.inputQr.focusInput();
      }, 200);
    },
    inputScan() {
      if (this.code === REMOVE_ITEM_CODE) {
        if (this.items.length === 0 && this.exchangeItems.length === 0) {
          this.code = null;
          this.$vToastify.warning("Chưa có hàng hóa");
          return false;
        }
        this.showRemoveItemDialog();
      } else {
        this.uidScan();
      }
    },
    onHiddenInfoClick() {
      this.isScanHidden = !this.isScanHidden;
      if (this.isScanHidden) {
        this.buyerCode = BUYER_HIDDEN_INFO_CODE;
        this.buyerScan();
      } else {
        setTimeout(() => {
          this.$refs.inputQrBuyer.focus();
        }, 200);
      }
    },
    async buyerScan() {
      if (this.buyerCode === BUYER_HIDDEN_INFO_CODE) {
        this.buyerCode = null;
        this.$refs.inputQr.focusInput();
      } else {
        const buyerKey = POS_ORDER_BUYER_STEP_KEYS[this.buyerStep];
        if (buyerKey === "phone" && !this.isPhoneValid(this.buyerCode)) {
          this.$vToastify.warning(this.$t("messages.wrong_phone"));
          this.buyerCode = null;
          return false;
        }
        if (
          buyerKey === "birthday_day" &&
          !this.isBirthDayValid(this.buyerCode)
        ) {
          this.$vToastify.warning(this.$t("messages.wrong_birthday_day"));
          this.buyerCode = null;
          return false;
        }
        if (
          buyerKey === "birthday_month" &&
          !this.isBirthMonthValid(this.buyerCode)
        ) {
          this.$vToastify.warning(this.$t("messages.wrong_birthday_month"));
          this.buyerCode = null;
          return false;
        }
        if (
          buyerKey === "birthday_year" &&
          !this.isBirthYearValid(this.buyerCode)
        ) {
          this.$vToastify.warning(this.$t("messages.wrong_birthday_year"));
          this.buyerCode = null;
          return false;
        }
        if (buyerKey === "gender") {
          if (this.buyerCode === GENDER_MALE_CODE) {
            this.buyer = { ...this.buyer, [buyerKey]: 1 };
          } else if (this.buyerCode === GENDER_FEMALE_CODE) {
            this.buyer = { ...this.buyer, [buyerKey]: 2 };
          } else {
            this.$vToastify.warning(this.$t("messages.wrong_code"));
          }
        } else {
          this.buyer = { ...this.buyer, [buyerKey]: this.buyerCode };
        }
        if (this.isPhoneValid(this.buyerCode)) {
          const { data } = await httpClient.post("/get-buyer-by-phone", {
            phone: this.buyerCode,
            id_pos: this.idPos,
          });
          if (data) {
            this.buyer = { ...this.buyer, ...data };
          }
        }
        if (this.buyer.name) {
          this.onBuyerNameUpdate();
        }
        Object.keys(POS_ORDER_BUYER_STEP_KEYS).forEach((key) => {
          const checkKey = POS_ORDER_BUYER_STEP_KEYS[key];
          const nextKey = +key + 1;
          console.log("nextKey", nextKey);
          if (this.buyer[checkKey] || this.buyer[checkKey] == 0) {
            this.buyerStep = nextKey;
          }
          if (this.buyerStep > 5) {
            this.$refs.inputQr.focusInput();
          }
        });
        this.buyerCode = null;
      }
    },
    onBuyerNameUpdate() {
      this.$emit("nameUpdated", {
        index: this.itemIndex,
        name: this.buyer.name,
      });
    },
    showConfirmReturnDialog(item) {
      this.returnItem = { ...item };
      this.dialogConfirmReturn = true;
    },
    hideConfirmReturnDialog() {
      this.dialogConfirmReturn = false;
    },
    async confirmReturnItem(data) {
      const checkItem = this.exchangeItems.find(
        (item) => item.id_goods === data.id_goods
      );
      if (!checkItem) {
        this.exchangeItems.push({
          id_goods: data.id_goods,
          name: data.name,
          customer_goods_barcode: data.customer_goods_barcode,
          sku: data.sku,
          size: data.size,
          description: data.description,
          available_quantity: data.available_quantity,
          uids: [data.id],
          sku_uids: [data.sku_uid],
          baskets: [data.id_basket],
          reasons: [data.roll_reason],
          statuses: [data.roll_status],
        });
      } else {
        const checkItemIndex = this.exchangeItems.findIndex(
          (item) => item.id_goods === data.id_goods
        );
        this.exchangeItems[checkItemIndex].uids.push(data.id);
        this.exchangeItems[checkItemIndex].sku_uids.push(data.sku_uid);
        this.exchangeItems[checkItemIndex].baskets.push(data.id_basket);
        this.exchangeItems[checkItemIndex].reasons.push(data.roll_reason);
        this.exchangeItems[checkItemIndex].statuses.push(data.roll_status);
        this.exchangeItems = [...this.exchangeItems];
      }

      await this.getPrice();
      await this.getVouchers();
    },
    async uidScan() {
      if (this.skuUidCodes.includes(this.code)) {
        this.$root.$emit("playErrorAudio");
        this.$vToastify.error(this.$t("messages.uid_scanned"));
        this.code = null;
        return false;
      }

      if (this.isLoading) {
        this.$root.$emit("playErrorAudio");
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/get-item-for-order", {
          code: this.code,
          id_pos: this.idPos,
        });
        this.code = null;
        this.isLoading = false;

        /* if (data.type !== 'exchange') {
                    this.$root.$emit('playErrorAudio')
                    this.$vToastify.error('Không phải mã đổi trả, cần nhập thông tin khách hàng trước');
                    return false
                } */

        /* if (data.type === 'exchange' && this.items.length > 0) {
                    this.$root.$emit('playErrorAudio')
                    this.$vToastify.error('Đang xử lý hóa đơn bán hàng. Đổi trả sản phẩm có thể thực hiện sau khi hoàn thành bán hàng');
                    return false
                }

                if (data.type === 'new' && this.exchangeItems.length > 0) {
                    this.$root.$emit('playErrorAudio')
                    this.$vToastify.error('Đang xử lý hóa đơn đổi trả. Đơn hàng bán có thể thực hiện sau khi hoàn thành hóa đơn đổi trả');
                    return false
                } */

        if (data.type === "exchange") {
          if (data.buyer && !this.buyer.phone) {
            this.buyer = { ...data.buyer };
            if (this.buyer.name) {
              this.onBuyerNameUpdate();
            }
            if (this.buyer.birthday_year) {
              this.buyerStep = 6;
              this.$refs.inputQr.focusInput();
            }
          }

          const checkItem = this.exchangeItems.find(
            (item) => item.id_goods === data.id_goods
          );
          if (checkItem && checkItem.uids.includes(data.id)) {
            this.$root.$emit("playErrorAudio");
            this.$vToastify.error(this.$t("messages.uid_scanned"));
            return false;
          }

          this.showConfirmReturnDialog(data);
          return false;
        } else {
          const checkItem = this.items.find(
            (item) => item.id_goods === data.id_goods
          );
          if (!checkItem) {
            this.items.push({
              id_goods: data.id_goods,
              name: data.name,
              customer_goods_barcode: data.customer_goods_barcode,
              sku: data.sku,
              size: data.size,
              description: data.description,
              available_quantity: data.available_quantity,
              uids: [data.id],
              sku_uids: [data.sku_uid],
            });
          } else {
            const checkItemIndex = this.items.findIndex(
              (item) => item.id_goods === data.id_goods
            );
            if (checkItem.uids.includes(data.id)) {
              this.$root.$emit("playErrorAudio");
              this.$vToastify.error(this.$t("messages.uid_scanned"));
              return false;
            }
            this.items[checkItemIndex].uids.push(data.id);
            this.items[checkItemIndex].sku_uids.push(data.sku_uid);
            this.items = [...this.items];
          }
        }

        await this.getPrice();
        await this.getVouchers();
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        if (errMsg === "Không tìm thấy UID" && !this.code.includes("@")) {
          this.isLoading = false;
          return this.packageScan();
        }
        this.$root.$emit("playErrorAudio");
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.code = null;
      }
    },
    async getPrice() {
      const { data } = await httpClient.post("/get-order-price", {
        id_voucher: this.payment.id_voucher,
        id_buyer: this.buyer.id,
        id_pos: this.idPos,
        items: this.items,
        exchange_items: this.exchangeItems,
      });
      this.price = { ...data };
    },
    async getVouchers() {
      this.vouchers = [];
      if (!this.buyer.id) {
        return false;
      }
      const { data } = await httpClient.post("/get-buyer-voucher", {
        id_buyer: this.buyer.id,
        id_pos: this.idPos,
        ...this.price,
      });
      if (!data || data.length === 0) {
        return false;
      }
      this.vouchers = [...data].map((d) => {
        const discount = d.discount_value
          ? `${this.formatNumber(d.discount_value)} đ`
          : `${d.discount_percent * 100} %`;
        const isSaleProduct = d.is_discount_sale_product
          ? " - Áp dụng sp sale"
          : " - Không áp dụng sp sale";
        return {
          text: `${d.code} (${discount}${isSaleProduct})`,
          value: d.id,
        };
      });
      if (this.payment.id_voucher) {
        const checkVoucher = this.vouchers.find(
          (v) => v.value === this.payment.id_voucher
        );
        if (!checkVoucher) {
          this.payment = { ...this.payment, id_voucher: null };
          await this.getPrice();
        }
      }
    },
    removePackaging(id) {
      this.packages = [...this.packages.filter((p) => p.id !== id)];
    },
    async packageScan() {
      /*if (this.items.length === 0) {
                this.$vToastify.warning(this.$t('messages.uid_required'));
            }*/

      const checkPackage = this.packages.find(
        (p) => p.barcode.toUpperCase() === this.code.toUpperCase()
      );
      if (checkPackage) {
        const packs = [...this.packages].map((p) => {
          if (p.id == checkPackage.id) {
            p.quantity++;
          }
          return p;
        });
        this.packages = [...packs];
        this.code = null;
        return false;
      }

      if (this.isLoading) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/get-package", {
          code: this.code,
        });

        this.isLoading = false;
        this.code = null;
        this.packages.push({
          ...data,
          quantity: 1,
        });
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$root.$emit("playErrorAudio");
        this.$vToastify.error(errMsg);
      }
    },
    showRemoveItemDialog() {
      if (this.items.length === 0 && this.exchangeItems.length === 0) {
        this.code = null;
        this.$vToastify.warning("Chưa có hàng hóa");
        return false;
      }
      this.code = null;
      this.dialogRemoveItem = true;
    },
    hideRemoveItemDialog() {
      this.dialogRemoveItem = false;
    },
    async confirmRemoveItem(confirm_code) {
      console.log(confirm_code, this.skuUidCodes);
      const checkUid = this.skuUidCodes.find((b) => b === confirm_code);
      if (!checkUid) {
        this.$root.$emit("playErrorAudio");
        this.$vToastify.error("UID không đúng");
        return false;
      }
      this.items = [...this.items]
        .map((item) => {
          if (item.sku_uids.includes(confirm_code)) {
            const uidIndex = item.sku_uids.findIndex(
              (su) => su === confirm_code
            );
            item.sku_uids = item.sku_uids.filter((s, i) => i !== uidIndex);
            item.uids = item.uids.filter((s, i) => i !== uidIndex);
          }
          return item;
        })
        .filter((item) => item.uids.length > 0);
      this.exchangeItems = [...this.exchangeItems]
        .map((item) => {
          if (item.sku_uids.includes(confirm_code)) {
            const uidIndex = item.sku_uids.findIndex(
              (su) => su === confirm_code
            );
            item.sku_uids = item.sku_uids.filter((s, i) => i !== uidIndex);
            item.uids = item.uids.filter((s, i) => i !== uidIndex);
          }
          return item;
        })
        .filter((item) => item.uids.length > 0);
      await this.getPrice();
      await this.getVouchers();
    },
    onPrinted() {
      this.isPrinted = true;
      this.$root.$emit("playSuccessAudio");
      this.$emit("itemReset", {
        index: this.itemIndex,
      });
    },
    printAgain() {
      this.isPrinted = false;
    },
    async createOrder() {
      if (this.isDisableBtnSubmit) {
        return false;
      }

      if (this.isLoading) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/create-order", {
          items: this.items,
          exchange_items: this.exchangeItems,
          packages: this.packages,
          payment: this.payment,
          buyer: this.buyer,
          id_employee_care: this.id_employee_care || 0,
          id_source: this.id_source || 0,
          id_pos: this.idPos,
          ui_price: this.price,
        });
        this.isPrinted = false;
        this.createdOrder = { ...data };
        /* await this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/generate-order-bill`, {
                    tracking_id: data,
                }, `80x100_${data}.pdf`) */
        this.isLoading = false;
        this.code = null;

        /* this.$emit('itemReset', {
                    index: this.itemIndex,
                }) */
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        this.$root.$emit("playErrorAudio");
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        if (errMsg === "wrong_payment_price") {
          await this.getPrice();
          await this.getVouchers();
          this.$vToastify.warning(this.$t("messages.wrong_payment_price"));
          return false;
        }
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
